/* Org Header */
.boxel-org-header {
  --boxel-org-header-logo-size: auto 2.5rem;
  --boxel-org-header-logo-position: center;
  --boxel-org-header-padding: var(--boxel-sp-lg);
  --boxel-org-header-background-color: inherit;
  --boxel-org-header-color: inherit;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: var(--boxel-sp);
  padding: var(--boxel-org-header-padding);
  background-color: var(--boxel-org-header-background-color);
  color: var(--boxel-org-header-color);
}
