.boxel-participant {
  display: grid;
  grid-template-columns: var(--boxel-participant-icon-size) 1fr;
  gap: 0 var(--boxel-sp-xs);
  align-items: center;
  font: var(--boxel-font-xs);
  letter-spacing: var(--boxel-lsp-lg);
}

.boxel-participant.boxel-participant__vertical {
  grid-template-columns: none;
  grid-template-rows: var(--boxel-participant-icon-size) 1fr;
  gap: var(--boxel-sp-xs) 0;
}

.boxel-participant__icon-only {
  display: inline-block;
}

.boxel-participant__image {
  width: var(--boxel-participant-icon-size);
  height: var(--boxel-participant-icon-size);
  background-image: url("/@cardstack/boxel/images/placeholders/profile.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 100px;
}

.boxel-participant__has-logo .boxel-participant__image {
  border-radius: initial;
}

.boxel-participant__title {
  margin: 0;
  font: 600 var(--boxel-font-xs);
}

.boxel-participant__has-logo .boxel-participant__title {
  font: 600 var(--boxel-font-sm);
}

.boxel-participant__description {
  margin: 0;
  color: var(--boxel-purple-400);
}
