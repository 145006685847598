.card-drop-page-page-layout {
  --page-content-max-width: 720px;
  --degraded-service-banner-content-max-width: var(--page-content-max-width);

  position: relative;
  min-height: 100%;
  display: grid;
  grid-template-rows: auto auto auto 1fr;
  grid-template-columns: minmax(200px, var(--page-content-max-width));
  gap: var(--boxel-sp);
  align-items: center;
  justify-content: center;
  background-color: var(--boxel-purple-900);
  color: var(--boxel-light);
  font: var(--boxel-font);
  letter-spacing: var(--boxel-lsp-lg);
  z-index: 0;
  padding: 0 var(--boxel-sp) var(--boxel-sp-xxxl) var(--boxel-sp);
}
