.boxel-ranged-number-picker {
  display: inline-block;
}

.boxel-ranged-number-picker .ember-power-select-status-icon {
  background: url("/@cardstack/boxel/images/icons/caret-down.svg") no-repeat;
  width: 11px;
  height: 9px;
  display: inline-block;
  filter: opacity(29%);
  margin-right: var(--boxel-sp-xxs);
}

.boxel-ranged-number-picker + .ember-basic-dropdown-content-wormhole-origin {
  position: absolute;
}

.boxel-ranged-number-picker__dropdown ul {
  text-align: center;
  max-height: var(--boxel-xs-container);
}

.boxel-ranged-number-picker__item {
  display: inherit;
}

.boxel-ranged-number-picker--selected .boxel-ranged-number-picker__item {
  padding: 0;
}
