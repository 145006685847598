.boxel-input-selectable-token-amount {
  --boxel-input-selectable-token-amount-input-font-size: var(--boxel-font-size);

  position: relative;
  width: 100%;
  font-family: var(--boxel-font-family);
  font-size: var(--boxel-input-selectable-token-amount-input-font-size);
}

.boxel-input-selectable-token-amount__select[aria-disabled="true"] {
  opacity: 0.5;
}

.boxel-input-selectable-token-amount__dropdown-item {
  white-space: nowrap;
}

.boxel-input-selectable-token-amount__dropdown .ember-power-select-options[role="listbox"] {
  max-height: 18em;
}
