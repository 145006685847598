.boxel-progress-circle {
  --progress-arc-color: var(--boxel-highlight);
  --progress-arc-background-color: var(--boxel-light-400);

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto;
}

.boxel-progress-circle__pie {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
}

.boxel-progress-circle__background-circle {
  stroke: var(--progress-arc-background-color);
  fill: none;
}

.boxel-progress-circle__indicator-circle {
  stroke: var(--progress-arc-color);
  fill: none;
  stroke-dasharray: 0 360; /* Note: this is overridden by an inline style to show a progress arc of the right length */
  transition: stroke-dasharray var(--boxel-transition);
}

.boxel-progress-circle__pct-label {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  box-shadow: 0 0 4px rgb(0 0 0 / 15%);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1;
  z-index: 1;
}
