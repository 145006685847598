@import "./kind-variants.css";
@import "./size-variants.css";

/**
 * This file provides base styles for a button, and an interface to accept variables defined in
 * size-variants.css and kind-variants.css
 */

.boxel-button {
  display: inline-flex;
  justify-content: center;
  height: min-content;
  align-items: center;
  border-radius: 100px;
  white-space: nowrap;
  transition:
    background-color var(--boxel-transition),
    border var(--boxel-transition);

  /* kind variants + disabled state */
  border: var(--boxel-button-border, var(--boxel-border));
  color: var(--boxel-button-text-color, black);
  background-color: var(--boxel-button-color, transparent);

  /* size variants */
  font: var(--boxel-button-font, var(--boxel-font-sm));
  min-height: var(--boxel-button-min-height);
  min-width: var(--boxel-button-min-width, 120px);
  padding: var(--boxel-button-padding, var(--boxel-sp-xs) var(--boxel-sp-sm));
  letter-spacing: var(--boxel-button-letter-spacing, var(--boxel-lsp-lg));
}

.boxel-button__loading-indicator {
  width: var(--boxel-button-loading-icon-size);
  height: var(--boxel-button-loading-icon-size);
  margin-right: var(--boxel-sp-xxxs);
}

/* select disabled buttons and links that don't have href */

/*
  a.boxel-button--disabled-link is a special case for an automatically appended class by the LinkTo component
  the LinkTo component appends the href regardless, so we have to select it in other ways.
  Removing the chained classes will make kind-variants overwrite the disabled style on the LinkTo (specificity issues)
*/
.boxel-button:disabled,
a.boxel-button:not([href]),
a.boxel-button[href=""],
a.boxel-button.boxel-button--disabled-link {
  --boxel-button-color: var(--boxel-purple-300);
  --boxel-button-border: 1px solid var(--boxel-purple-300);
  --boxel-button-text-color: var(--boxel-purple-600);

  cursor: default;
}

/* the a element does not have a disabled attribute. Clicking will still trigger event listeners */
a.boxel-button:not([href]),
a.boxel-button[href=""],
a.boxel-button.boxel-button--disabled-link {
  pointer-events: none;
}

/*
  loading state.
  this should only be relevant for buttons - links shouldn't need it.
  We want to preserve the "normal" styling of the button but not allow interaction
*/
.boxel-button--loading {
  pointer-events: none;
}

/* overwrite the global style for links in global.css */
a.boxel-button:hover {
  color: var(--boxel-button-text-color);
}

.boxel-button--with-tooltip {
  pointer-events: unset !important;
  position: relative;
}

.boxel-button--with-tooltip::after {
  content: attr(data-hover);
  opacity: 0;
  background-color: var(--boxel-light);
  box-shadow: 0 0 0 1px var(--boxel-light-500);
  color: var(--boxel-dark);
  text-align: center;
  border-radius: var(--boxel-sp-sm);
  padding: var(--boxel-sp-sm);
  transition: opacity 1s ease-in-out;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 110%;
}

.boxel-button--with-tooltip:hover::after {
  opacity: 1;
  visibility: visible;
}
