/* essentially reset fieldset and hide legend from visibility */
.boxel-radio-fieldset {
  border: 0;
  padding: 0.01em 0 0;
  min-width: 0;
}

.boxel-radio-fieldset__legend {
  opacity: 0;
  position: absolute;
  left: -9999px;
  max-width: 1px;
  max-height: 1px;
  white-space: nowrap;
}

/* Div container inside the fieldset component. Use `display: contents` to move
   these styles up when that css property is more widely available. */
.boxel-radio-fieldset__container {
  --boxel-radio-gap: var(--boxel-sp);

  display: flex;
  flex-wrap: wrap;
  gap: var(--boxel-radio-gap);
  width: 100%;
  max-width: 100%;
}

.boxel-radio-fieldset__container--vertical {
  flex-direction: column;
}

.boxel-radio-fieldset__container--compact {
  --boxel-radio-gap: var(--boxel-sp-xxs);
  --boxel-radio-input-option-padding: var(--boxel-sp-xxxs);
  --boxel-radio-input-option-gap: var(--boxel-sp-xxxs);
}

.boxel-radio-fieldset__container--horizontal > * {
  flex: 1;
}
