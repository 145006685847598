@import "./action-status-area/index.css";

.boxel-action-chin {
  --boxel-action-chin-padding-horizontal: var(--boxel-sp-lg);
  --boxel-action-chin-padding-vertical: var(--boxel-sp-lg);

  /* The height of the boxel button, used for calculating the min-height to reduce layout shift */
  --button-base-height: 2rem;
  --boxel-action-chin-background-color: var(--boxel-purple-750);
  --boxel-action-chin-emphasis-text-color: var(--boxel-light);
  --boxel-action-chin-text-color: var(--boxel-purple-300);
  --icon-color: var(--boxel-action-chin-text-color);
  --boxel-action-chin-disabled-background: rgb(54 52 65 / 90%); /* bg-color at 90% opacity */

  position: relative;
  display: grid;
  grid-template-columns: auto auto minmax(var(--boxel-sp-sm), 1fr) auto auto;
  grid-template-rows: auto;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  grid-template-areas: "main cancel _ info lock-icon";
  align-items: center;
  padding: var(--boxel-action-chin-padding-vertical) var(--boxel-action-chin-padding-horizontal);
  background-color: var(--boxel-action-chin-background-color);
  color: var(--boxel-action-chin-text-color);
  font: var(--boxel-font-xs);
  transition: background-color var(--boxel-transition), padding var(--boxel-transition);
}

/* minimize layout shift by calculating a min-height for those cta blocks without steps */
.boxel-action-chin:not(.boxel-action-chin--has-step) {
  min-height: calc(var(--button-base-height) + var(--boxel-action-chin-padding-vertical) * 2);
}

.boxel-action-chin--memorialized {
  --boxel-action-chin-background-color: var(--boxel-purple-100);
  --boxel-action-chin-emphasis-text-color: var(--boxel-dark);
  --boxel-action-chin-text-color: var(--boxel-purple-400);
}

.boxel-action-chin + .boxel-action-chin {
  border-top: 1px solid var(--boxel-purple-700);
}

.boxel-action-chin--memorialized + .boxel-action-chin--memorialized {
  border-top: 1px solid var(--boxel-light-600);
}

/* paddings are different if there is a step */
.boxel-action-chin--has-step {
  --boxel-action-chin-padding-horizontal: var(--boxel-sp-xl);
  --boxel-action-chin-padding-vertical: var(--boxel-sp-xl);
}

.boxel-action-chin--memorialized.boxel-action-chin--has-step,
.boxel-action-chin--disabled.boxel-action-chin--has-step {
  --boxel-action-chin-padding-vertical: var(--boxel-sp-xs);
}

.boxel-action-chin--disabled.boxel-action-chin--has-step:not(.boxel-action-chin--memorialized) {
  --boxel-action-chin-background-color: var(--boxel-action-chin-disabled-background);
}

/* add the step using a pseudoelement */
.boxel-action-chin__step {
  position: absolute;
  width: var(--boxel-action-chin-padding-horizontal);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.boxel-action-chin__step::before {
  content: attr(data-step);
  font: 700 var(--boxel-font-sm);
  color: var(--boxel-action-chin-emphasis-text-color);
}

/* grid elements */
.boxel-action-chin__action-button {
  grid-area: main;
}

.boxel-action-chin__cancel-button {
  grid-area: cancel;
  margin-left: var(--boxel-sp);
}

.boxel-action-chin__info-area {
  grid-area: info;

  /*
  some defaults so that people can simply enter text or buttons
  if they want to supply more complex content they should be able to overwrite it
  */
  column-gap: var(--boxel-sp-xs);
  display: flex;
  align-items: center;
  justify-content: center;
}

.boxel-action-chin__action-status-area {
  grid-area: main;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 600 var(--boxel-font-sm);
  color: var(--boxel-action-chin-emphasis-text-color);
}
