.boxel-action-container {
  max-width: 43.75rem; /* 700px; */
  min-width: 20rem;
  color: var(--boxel-dark);
  font: var(--boxel-font);
  letter-spacing: var(--boxel-lsp);
  box-shadow: 0 15px 30px rgb(0 0 0 / 15%);
}

/* Card Header */
.boxel-action-container__header {
  --boxel-header-text-color: var(--boxel-purple-400);
  --boxel-header-background-color: transparent;

  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.boxel-action-container:hover .boxel-action-container__header {
  background-color: var(--boxel-purple-100);
  color: var(--boxel-dark);
}

/* Card Chin */
.boxel-action-container__footer {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
