.boxel-dropdown__content {
  border-radius: var(--boxel-border-radius);
  box-shadow: 0 5px 15px 0 rgb(0 0 0 / 25%);
}

.boxel-dropdown__content.ember-basic-dropdown-content--below.ember-basic-dropdown--transitioning-in {
  animation: drop-fade-below var(--boxel-transition);
}

.boxel-dropdown__content.ember-basic-dropdown-content--below.ember-basic-dropdown--transitioning-out {
  animation: drop-fade-below var(--boxel-transition) reverse;
}
