.boxel-add-participant-button {
  display: grid;
  grid-template-columns: 30px 1fr;
  gap: 0 var(--boxel-sp-xs);
  width: 100%;
  align-items: center;
  height: 40px;
  background: none;
  border: none;
  padding: 0;
  font: var(--boxel-font-xs);
  color: var(--boxel-purple-400);
  letter-spacing: var(--boxel-lsp-lg);
}

.boxel-add-participant-button__label {
  text-align: left;
}

.boxel-add-participant-button__icon {
  width: 30px;
  height: 30px;
  border: 1px solid var(--boxel-light-400);
  border-radius: 100px;
}

.boxel-add-participant-button__icon > svg {
  height: 100%;
}
