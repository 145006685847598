.boxel-left-main-nav {
  --gutter-width: 1.5rem;

  display: grid;
  grid-template-rows: auto auto 1fr;
  row-gap: var(--gutter-width);
  justify-items: center;
  height: 100%;
  width: 16rem;
  background-color: var(--boxel-purple-600);
}

.boxel-left-main-nav .boxel-org-title {
  --boxel-org-title-color: var(--boxel-light);
  --boxel-org-title-font: 600 var(--boxel-font-size) var(--boxel-font-family);
  --boxel-org-title-letter-spacing: normal;
  --boxel-org-title-text-transform: none;

  margin: var(--gutter-width);
  width: calc(100% - var(--gutter-width) * 2);
}

.boxel-left-main-nav__searchbox {
  width: calc(100% - var(--gutter-width) * 2);
  margin: 0 var(--gutter-width);
}

.boxel-left-main-nav__nav {
  width: 100%;
  margin: 0 var(--gutter-width);
  padding: 0;
  display: grid;
  grid-auto-rows: var(--boxel-sp-xxl);
  font-size: var(--boxel-font-size-sm);
}

.boxel-left-main-nav__link {
  padding: var(--gutter-width);
  color: var(--boxel-light);
  display: flex;
  align-items: center;
}

.boxel-left-main-nav__link--is-active {
  background: var(--boxel-dark);
  color: var(--boxel-cyan);
}
