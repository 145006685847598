/**
 * Size variants - variants that control the size and spacing of a button
 *
 * The @size argument on the button component should create a corresponding class with format
 * boxel-button--size-@size
 *
 * Classes for the @size argument can control the following properties:
 *
 * --boxel-button-padding
 * --boxel-button-min-width
 * --boxel-button-min-height
 * --boxel-button-font
 * --boxel-button-letter-spacing
 * --boxel-button-loading-icon-size
 *
 */

.boxel-button--size-extra-small {
  --boxel-button-padding: var(--boxel-sp-xxxs) var(--boxel-sp);
  --boxel-button-font: var(--boxel-font-xs);
  --boxel-button-loading-icon-size: var(--boxel-font-size-xs);
  --boxel-button-letter-spacing: var(--boxel-lsp-lg);
  --boxel-button-min-height: 1.8125rem;
}

/* thinner base button */
.boxel-button--size-small {
  --boxel-button-padding: var(--boxel-sp-xxxs) var(--boxel-sp);
  --boxel-button-font: 600 var(--boxel-font-sm);
  --boxel-button-loading-icon-size: var(--boxel-font-size-sm);
  --boxel-button-letter-spacing: var(--boxel-lsp);
  --boxel-button-min-height: 2rem;
}

.boxel-button--size-base {
  --boxel-button-padding: var(--boxel-sp-xxxs) var(--boxel-sp-xl);
  --boxel-button-font: 600 var(--boxel-font-sm);
  --boxel-button-loading-icon-size: var(--boxel-font-size-sm);
  --boxel-button-letter-spacing: var(--boxel-lsp);
  --boxel-button-min-height: 2rem;
}

/* tall but thinner button */
.boxel-button--size-tall {
  --boxel-button-padding: var(--boxel-sp-xs) var(--boxel-sp-lg);
  --boxel-button-font: 600 var(--boxel-font-sm);
  --boxel-button-loading-icon-size: var(--boxel-font-size-sm);
  --boxel-button-letter-spacing: var(--boxel-lsp);
  --boxel-button-min-height: 2.5rem;
}

/*
  extra tall button mainly used in mobile screens
  touchable as it's bigger
  */
.boxel-button--size-touch {
  --boxel-button-padding: var(--boxel-sp-xs) var(--boxel-sp-lg);
  --boxel-button-font: 600 var(--boxel-font);
  --boxel-button-loading-icon-size: var(--boxel-font-size);
  --boxel-button-letter-spacing: var(--boxel-lsp-xs);
  --boxel-button-min-height: 3rem;
}
