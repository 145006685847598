.boxel-card-picker {
  --boxel-card-picker-width: 24rem;
  --boxel-card-picker-dropdown-menu-height: 33rem;

  position: relative;
  max-width: var(--boxel-card-picker-width);
}

.boxel-card-picker--change-card {
  display: flex;
  justify-content: space-between;
}

.boxel-card-picker__select {
  position: static;
  max-width: 100%;
  color: var(--boxel-dark);
  font: var(--boxel-font);
  letter-spacing: var(--boxel-lsp-sm);
  padding:
    var(--boxel-sp-xs) var(--boxel-sp-xxxl) var(--boxel-sp-xs)
    var(--boxel-sp);
  background:
    url("/@cardstack/boxel/images/icons/caret-down.svg") right
    var(--boxel-sp) center/auto 0.5rem no-repeat;
  background-color: var(--boxel-light);
  border: 1px solid var(--boxel-purple-300);
  border-radius: var(--boxel-border-radius);
  transition: border-color var(--boxel-transition);
}

.boxel-card-picker__dropdown {
  --boxel-select-current-color: var(--boxel-purple-100);
  --boxel-select-selected-color: transparent;

  background-color: var(--boxel-light);
  border: 1px solid rgb(0 0 0 / 15%);
  border-radius: var(--boxel-border-radius);
  box-shadow: 0 15px 30px rgb(0 0 0 / 25%);
  width: var(--boxel-card-picker-width);
  position: absolute;
  top: 0;
  left: 0;
}

.boxel-card-picker--change-card .boxel-card-picker__dropdown {
  top: -0.15rem;
  left: -17rem;
}

.boxel-card-picker__select--selected {
  padding: var(--boxel-sp-xxxs) var(--boxel-sp);
  background-image: none;
  border-radius: 100px;
  font: var(--boxel-font-xs);
  letter-spacing: var(--boxel-lsp-lg);
}

.boxel-card-picker__dropdown .ember-power-select-options {
  margin: 0;
  padding: 0;
  border-radius: inherit;
  max-height: var(--boxel-card-picker-dropdown-menu-height);
  overflow-y: auto;
}

.boxel-card-picker__dropdown .ember-power-select-option {
  padding: var(--boxel-sp) var(--boxel-sp-xxxl);
  background-image: url("/@cardstack/boxel/images/icons/icon-circle-light.svg");
  background-repeat: no-repeat;
  background-position: left var(--boxel-sp) center;
  background-size: var(--boxel-icon-sm) var(--boxel-icon-sm);
}

.boxel-card-picker__dropdown .ember-power-select-option + .ember-power-select-option {
  border-top: 1px solid var(--boxel-light-400);
}

.boxel-card-picker__dropdown .ember-power-select-option[aria-selected="true"] {
  background-image: url("/@cardstack/boxel/images/icons/success-bordered.svg");
}

/* stylelint-disable-next-line max-line-length */
.boxel-card-picker__dropdown .ember-power-select-option:hover:not([aria-disabled="true"]):not(.ember-power-select-option--no-matches-message) {
  background-color: var(--boxel-purple-100);
  cursor: pointer;
}

.boxel-card-picker__dropdown .ember-power-select-option[aria-disabled="true"] {
  opacity: 0.45;
  cursor: not-allowed;
}

.boxel-card-picker__dropdown .ember-power-select-option--no-matches-message {
  background-image: none;
}

.boxel-card-picker .ember-basic-dropdown .boxel-card-picker__select--selected {
  display: inline;
}

.boxel-card-picker__selected-card {
  margin-right: var(--boxel-sp-xxs);
  flex: 1;
}
