.tippy-box[data-theme~="address"] .tippy-content {
  padding: 5px 9px;
  z-index: 1;
  word-wrap: break-word;
  max-width: calc(22ch + 9px * 2);
  font-family: var(--boxel-monospace-font-family);
  line-height: var(--address-line-height);
  letter-spacing: var(--boxel-lsp-sm);
  word-break: break-all;
}
