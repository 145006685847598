.boxel-input-date__content {
  background-color: transparent;
  box-shadow: none;
}

.boxel-input-date__calendar-container {
  padding-top: 0.2rem;
  padding-bottom: 0.35rem;
}

.boxel-input-date__trigger:disabled {
  --boxel-button-color: transparent;
  --boxel-button-text-color: rgb(0 0 0 / 50%);
}
