/**
 * Kind variants - variants that control the colors on a button
 *
 * The @kind argument on the button component should create a corresponding class with format
 * boxel-button--kind-@kind
 *
 * Classes for the @kind argument can control the following properties:
 *
 * --boxel-button-color
 * --boxel-button-border
 * --boxel-button-text-color
 *
 */

.boxel-button--kind-primary:not(:disabled) {
  --boxel-button-color: var(--boxel-highlight);
  --boxel-button-border: 1px solid var(--boxel-highlight);
  --boxel-button-text-color: var(--boxel-dark);
}

.boxel-button--kind-primary:not(:disabled):hover,
.boxel-button--kind-primary:not(:disabled):active {
  /* result from putting a 10% opacity black overlay over */
  --boxel-button-border: 1px solid var(--boxel-highlight-hover);
  --boxel-button-color: var(--boxel-highlight-hover);
}

.boxel-button--kind-secondary-dark:not(:disabled) {
  /* transparent on dark background */
  --boxel-button-color: transparent;
  --boxel-button-border: 1px solid var(--boxel-purple-400);
  --boxel-button-text-color: var(--boxel-light);
}

.boxel-button--kind-secondary-dark:not(:disabled):hover,
.boxel-button--kind-secondary-dark:not(:disabled):active {
  --boxel-button-border: 1px solid var(--boxel-light);
}

.boxel-button--kind-secondary-light:not(:disabled) {
  /* transparent on light background */
  --boxel-button-color: transparent;
  --boxel-button-border: 1px solid var(--boxel-purple-300);
  --boxel-button-text-color: var(--boxel-dark);
}

.boxel-button--kind-secondary-light:not(:disabled):hover,
.boxel-button--kind-secondary-light:not(:disabled):active {
  --boxel-button-border: 1px solid var(--boxel-dark);
}

.boxel-button--kind-danger:not(:disabled) {
  --boxel-button-color: var(--boxel-danger);
  --boxel-button-border: 1px solid var(--boxel-danger);
  --boxel-button-text-color: var(--boxel-light-100);
}

.boxel-button--kind-danger:not(:disabled):hover,
.boxel-button--kind-danger:not(:disabled):active {
  --boxel-button-border: 1px solid var(--boxel-danger-hover);
  --boxel-button-color: var(--boxel-danger-hover);
}

.boxel-button--kind-primary-dark:not(:disabled) {
  --boxel-button-color: var(--boxel-dark);
  --boxel-button-border: 1px solid var(--boxel-dark);
  --boxel-button-text-color: var(--boxel-light);
}

.boxel-button--kind-primary-dark:not(:disabled):hover,
.boxel-button--kind-primary-dark:not(:disabled):active {
  --boxel-button-border: 1px solid var(--boxel-purple-800);
  --boxel-button-color: var(--boxel-purple-800);
}
