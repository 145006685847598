@import "./button/index.css";

.boxel-org-switcher {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-auto-rows: 2.5rem;
  gap: var(--boxel-sp);
  justify-items: center;
}
