.boxel-menu {
  --boxel-menu-color: var(--boxel-light);
  --boxel-menu-current-color: var(--boxel-light-100);
  --boxel-menu-selected-color: var(--boxel-highlight);
  --boxel-menu-disabled-color: var(--boxel-highlight);
  --boxel-menu-font: var(--boxel-font-sm);

  list-style-type: none;
  margin: 0;
  padding: 0;
}

.boxel-menu__item {
  background-color: var(--boxel-menu-color);
  font: var(--boxel-menu-font);
  letter-spacing: var(--boxel-lsp-sm);
}

.boxel-menu__item:hover {
  background-color: var(--boxel-menu-current-color);
  cursor: pointer;
}

.boxel-menu__item:first-child {
  border-radius: var(--boxel-border-radius) var(--boxel-border-radius) 0 0;
}

.boxel-menu__item:last-child {
  border-radius: 0 0 var(--boxel-border-radius) var(--boxel-border-radius);
}

.boxel-menu__item:only-child {
  border-radius: var(--boxel-border-radius);
}

.boxel-menu__item > a {
  width: 100%;
  padding: var(--boxel-sp-xs) var(--boxel-sp);
}

.boxel-menu__item--disabled a {
  cursor: default;
}

.boxel-menu__item > a:hover {
  color: inherit;
}

.boxel-menu__item--dangerous {
  color: var(--boxel-error-200);
}

.boxel-menu__item--selected,
.boxel-menu__item--selected.boxel-menu__item:hover {
  background-color: var(--boxel-menu-selected-color);
}

.boxel-menu__item--disabled,
.boxel-menu__item--disabled.boxel-menu__item:hover {
  background-color: initial;
  opacity: 0.4;
}

.boxel-menu__separator {
  margin: 0;
  border: 0;
  height: 0;
  border-bottom: 1px solid var(--boxel-purple-300);
}
