.boxel-progress-icon {
  --icon-color: var(--boxel-highlight);

  position: relative;
  background-image: url("/@cardstack/boxel/images/icons/progress-circle-dark.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.boxel--light-theme .boxel-progress-icon {
  --icon-color: var(--boxel-dark-highlight);

  background-image: url("/@cardstack/boxel/images/icons/progress-circle.svg");
}

.boxel-progress-icon.boxel-progress-icon--cancelled {
  background-image: url("/@cardstack/boxel/images/icons/icon-x-circle-ht.svg");
}

.boxel-progress-icon.boxel-progress-icon--complete {
  --icon-color: var(--boxel-highlight);

  background-image: url("/@cardstack/boxel/images/icons/icon-check-circle-ht.svg");
}

.boxel-progress-icon__progress-pie {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
  stroke-dasharray: 0 60;
  transition: stroke-dasharray var(--boxel-transition);
}
