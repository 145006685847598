.boxel-card-container {
  position: relative;
  background-color: var(--boxel-light);
  border-radius: var(--boxel-border-radius);
  transition:
    max-width var(--boxel-transition),
    box-shadow var(--boxel-transition);
}

.boxel-card-container--boundaries {
  box-shadow: 0 0 0 1px var(--boxel-light-500);
}

.boxel-card-container--highlighted {
  box-shadow: 0 0 0 2px var(--boxel-highlight);
}
