.boxel-styled-qr-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--boxel-sp-xxs);
  border-radius: 2.5rem;
  margin: auto;
  overflow: hidden;
  background: var(--boxel-light);
  border: 1px solid var(--boxel-light-400);
  width: calc(18.75rem + var(--boxel-sp-xxs) * 2);
}

.boxel-styled-qr-code__loading-indicator {
  width: 20%;
  height: 20%;
  margin: auto;
}

.boxel-styled-qr-code__canvas {
  width: 100%;
  aspect-ratio: 1;
  display: flex;
}

.boxel-styled-qr-code__canvas > canvas {
  width: 100%;
  height: 100%;
  max-width: 100%;
  border-radius: 2.5rem;
}

/* Hide this once the canvas is appended */
.boxel-styled-qr-code__loading-indicator:not(:only-child) {
  display: none;
}
