.boxel-action-container-section-title {
  font: 700 1.125rem/calc(24 / 18) var(--boxel-font-family);
  letter-spacing: 0;
}

.boxel-action-container-section-title--flex {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
}

.boxel-action-container-section-title--content {
  flex-grow: 1;
}

.boxel-action-container-section-title--icon {
  margin-right: var(--boxel-sp-sm);
  flex-shrink: 0;
}

.boxel-action-container-section-title--img {
  display: inline-block;
  width: 3.125rem;
  height: 3.125rem;
  margin-left: var(--boxel-sp-sm);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
