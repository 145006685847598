.boxel-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--boxel-sp-xxxs) 0 var(--boxel-sp-sm);
  min-height: var(--boxel-header-min-height, 1.875rem); /* 30px */
  background-color: var(--boxel-header-background-color, var(--boxel-purple-100));
  color: var(--boxel-header-text-color, var(--boxel-dark));
  border-top-right-radius: calc(var(--boxel-border-radius) - 1px);
  border-top-left-radius: calc(var(--boxel-border-radius) - 1px);
  font: 600 var(--boxel-font-xs);
  letter-spacing: var(--boxel-lsp-xl);
  text-transform: uppercase;
  transition:
    background-color var(--boxel-transition),
    color var(--boxel-transition);
}

.boxel-header--no-background {
  background-color: transparent;
  color: var(--boxel-header-text-color, var(--boxel-purple-400));
}

.boxel-header--highlighted {
  background-color: var(--boxel-highlight);
  color: var(--boxel-dark);
}

.boxel-header__content {
  display: flex;
  align-items: center;
}

.boxel-header__content button {
  --boxel-icon-button-width: var(--boxel-header-min-height, 1.875rem);
  --boxel-icon-button-height: var(--boxel-header-min-height, 1.875rem);

  min-height: var(--boxel-icon-button-height);
  padding: 0 var(--boxel-sp-xxxs);
  background: none;
  border: none;
  font: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
}
