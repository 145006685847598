.boxel-left-edge-nav-card-management-button {
  --icon-color: var(--boxel-highlight);

  display: inline-block;
  width: 40px;
  height: 40px;
  margin: auto;
  background: none;
  border: none;
}

.boxel-left-edge-nav-card-management-button svg {
  display: block;
  margin: auto;
}

.boxel-left-edge-nav-card-management-button:hover {
  --icon-color: var(--boxel-dark);

  cursor: pointer;
  background-color: var(--boxel-highlight);
  border-radius: 100px;
}
