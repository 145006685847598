.boxel-dashboard {
  --boxel-dashboard-left-edge-width: 5rem; /* 80px */
  --boxel-dashboard-background-color: inherit;
  --boxel-dashboard-color: inherit;

  position: relative;
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  background-color: var(--boxel-dashboard-background-color);
  color: var(--boxel-dashboard-color);
  font: var(--boxel-font);
  letter-spacing: var(--boxel-lsp-lg);
  z-index: 0;
}

.boxel-dashboard--with-left-edge {
  grid-template-columns: auto 1fr;
}

.boxel-dashboard__left-edge-container {
  grid-row: 1 / -1;
  grid-column: 1;
  min-width: var(--boxel-dashboard-left-edge-width);
}

/* Dark Theme */
.boxel-dashboard--dark-theme {
  --boxel-dashboard-background-color: var(--boxel-purple-600);
  --boxel-dashboard-color: var(--boxel-light);
  --boxel-link-highlight: var(--boxel-highlight);
}
