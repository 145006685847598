@font-face {
  font-family: "Open Sans";
  src: url("/@cardstack/boxel/fonts/OpenSans-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Open Sans";
  src: url("/@cardstack/boxel/fonts/OpenSans-LightItalic.ttf");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("/@cardstack/boxel/fonts/OpenSans-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Open Sans";
  src: url("/@cardstack/boxel/fonts/OpenSans-Italic.ttf");
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("/@cardstack/boxel/fonts/OpenSans-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Open Sans";
  src: url("/@cardstack/boxel/fonts/OpenSans-SemiBoldItalic.ttf");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("/@cardstack/boxel/fonts/OpenSans-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Open Sans";
  src: url("/@cardstack/boxel/fonts/OpenSans-BoldItalic.ttf");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("/@cardstack/boxel/fonts/OpenSans-ExtraBold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: "Open Sans";
  src: url("/@cardstack/boxel/fonts/OpenSans-ExtraBoldItalic.ttf");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("/@cardstack/boxel/fonts/RobotoMono-Regular.ttf");
  font-weight: 400;
}
