/* cardstack landing page */
.cardstack-landing-page-container {
  --card-width: 33.125rem;
  --horizontal-card-gap: var(--boxel-sp-lg);

  width: 100%;
  max-width: calc(var(--card-width) * 2 + var(--horizontal-card-gap) + var(--boxel-sp-lg) * 2);
  min-height: 100vh;
  margin: auto;
  padding: var(--boxel-sp);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.cardstack-landing-page-org-grid {
  width: 100%;
  list-style-type: none;
  padding: 0;
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(auto-fill, minmax(var(--card-width), 1fr));
  grid-template-rows: auto auto;
  gap: var(--boxel-sp-xl) var(--horizontal-card-gap);
}

.cardstack-landing-page-footer {
  margin-top: var(--boxel-sp-xxl);
}

@media screen and (max-width: 35rem) {
  .cardstack-landing-page-org-grid {
    grid-template-columns: 1fr;
    max-width: 100%;
  }

  .cardstack-landing-page-org-grid > li {
    width: 100%;
    max-width: 100%;
  }
}

.cardstack-landing-page-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  letter-spacing: 0.015em;
  margin-bottom: var(--boxel-sp);
}

.cardstack-landing-page-header__logo {
  width: 11rem;
}

.cardstack-landing-page-hero {
  width: 100%;
  min-height: 18.75rem;
  padding: 3.5rem 1.25rem 1.875rem;
  display: flex;
  justify-content: center;
  border-radius: var(--boxel-border-radius);
  background-color: var(--boxel-purple-900);
  background-image: url("/images/backgrounds/landing-page-hero-image.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  margin-bottom: var(--boxel-sp-xl);
}

.cardstack-landing-page-hero__title {
  margin: 0;
  font-weight: 300;
  letter-spacing: 0;
  font-size: 3.75rem;
}

.cardstack-landing-page-hero__content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.cardstack-landing-page-hero__description {
  margin: 0;
  font-size: 1.25rem;
  font-family: var(--boxel-font-family);
  letter-spacing: var(--boxel-lsp-sm);
  margin-bottom: var(--boxel-sp-lg);
}

.cardstack-landing-page-hero__cta {
  display: flex;
  align-items: center;
  margin-top: auto;
  font: 400 var(--boxel-font);

  --icon-color: var(--boxel-teal);
}

.cardstack-landing-page-hero__cta-icon {
  width: 0.75rem;
  height: 0.75rem;
  margin-right: var(--boxel-sp-xxs);
}
