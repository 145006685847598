.sort-menu-item {
  cursor: pointer;
  font-weight: 400;
  opacity: 0.5;
  padding-left: var(--boxel-sp-xs);
  padding-right: var(--boxel-sp-xs);
  position: relative;
  user-select: none;
}

.sort-menu-item + .sort-menu-item {
  padding-top: var(--boxel-sp-sm);
}

.sort-menu-item:hover,
.sort-menu-item:focus {
  opacity: 1;
}

.sort-menu-item__active {
  opacity: 1;
  font-weight: 700;
  transition:
    opacity var(--boxel-transition),
    font-weight var(--boxel-transition);
}

.sort-menu-item__active::before {
  content: url("/@cardstack/boxel/images/icons/checkmark-highlighted.svg");
  position: absolute;
  left: -10px;
}

.sort-menu-item__active > span {
  /* Not using variables so that the spacing between column and order is proportional to font-size */
  padding-left: 0.4em;
}

.sort-menu-item__active > span > svg {
  margin-left: 0.1em;
  margin-right: 0.1em;
}
