.boxel-searchbox {
  /* todo set up search stuff properly */
  --boxel-searchbox-icon-size: 0.875rem;

  font: var(--boxel-font-sm);
  letter-spacing: var(--boxel-lsp-lg);
  border: 1px solid var(--boxel-dark);
  border-radius: 100px;
  position: relative;
}

.boxel-searchbox--hide-icon {
  --boxel-searchbox-icon-size: calc(-1 * var(--boxel-sp-xxs));
}

.boxel-searchbox__input {
  --boxel-searchbox-text-color: rgb(255 255 255 / 60%);

  height: 2rem;
  border: none;
  width: 100%;
  /* stylelint-disable declaration-block-no-redundant-longhand-properties */
  padding-top: var(--boxel-sp-xxxs);
  padding-right: calc(var(--boxel-searchbox-icon-size) + var(--boxel-sp-xs) + var(--boxel-sp-xxxs));
  padding-bottom: var(--boxel-sp-xxxs);
  padding-left: calc(var(--boxel-searchbox-icon-size) + var(--boxel-sp-xs) + var(--boxel-sp-xs));
  /* stylelint-enable declaration-block-no-redundant-longhand-properties */
  border-radius: 100px;
  background-color: var(--boxel-purple-900);
  color: var(--boxel-light);
  font: var(--boxel-font-sm);
}

.boxel-searchbox__input::placeholder {
  color: var(--boxel-searchbox-text-color);
}

.boxel-searchbox__search-icon {
  --icon-color: var(--boxel-highlight);

  left: var(--boxel-sp-xs);

  /* absolutely position and vertically center these things, give them the same size */
  width: var(--boxel-searchbox-icon-size);
  height: var(--boxel-searchbox-icon-size);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
