.boxel-tab-bar {
  --boxel-tab-bar-background-color: inherit;
  --boxel-tab-bar-border-bottom: 1px solid var(--boxel-light-500);
  --boxel-tab-bar-font: inherit;
  --boxel-tab-bar-font-weight-hover: 600;
  --boxel-tab-bar-color-active: inherit;

  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: var(--boxel-tab-bar-border-bottom);
  background-color: var(--boxel-tab-bar-background-color);
  font: var(--boxel-tab-bar-font);
}

.boxel-tab-bar--spread {
  justify-content: space-around;
}

.boxel-tab-bar__item {
  --icon-color: currentcolor;

  display: flex;
  align-items: center;
  padding: var(--boxel-sp-xs) var(--boxel-sp-lg);
  gap: var(--boxel-sp-xs);
  border-bottom: 2px solid transparent;
}

.boxel-tab-bar__item--is-inactive {
  opacity: 0.5;
  cursor: text;
}

.boxel-tab-bar__item:not(.boxel-tab-bar__item--is-inactive):hover {
  font-weight: var(--boxel-tab-bar-font-weight-hover);
  cursor: pointer;
}

.boxel-tab-bar__item--is-inactive:hover {
  color: inherit;
  font-weight: inherit;
  cursor: text;
}

.boxel-tab-bar__item--is-active {
  color: var(--boxel-tab-bar-color-active);
  font-weight: var(--boxel-tab-bar-font-weight-hover);
  border-bottom: 2px solid var(--boxel-cyan);
}

/*
  Column flex and ::after with data-text allow bold-on-hover without layout shift:
  https://css-tricks.com/bold-on-hover-without-the-layout-shift/
*/

.boxel-tab-bar__item-text {
  display: flex;
  flex-direction: column;
}

.boxel-tab-bar__item-text::after {
  content: attr(data-text);
  height: 0;
  visibility: hidden;
  overflow: hidden;
  user-select: hidden;
  pointer-events: none;
  font-weight: var(--boxel-tab-bar-font-weight-hover);
}

@media speech {
  .boxel-tab-bar__item::after {
    display: none;
  }
}
